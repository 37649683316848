<template>
  <div class="time-block" :class="{ 'inactive': !active }">
    <div class="date-wrapper">
      {{ dateToDay(item.date) }}
    </div>
    <div class="time-wrapper">
      <img :src="active ? clockIcon : clockDarkIcon" alt="time" />
      {{ formatMilliseconds(item.time) }}
    </div>
  </div>
</template>

<script setup>
import { formatMilliseconds } from '@/utils/helpers/FormatTime.js';
import clockIcon from '@/assets/icons/ic_clock.svg';
import clockDarkIcon from '@/assets/icons/ic_clock_dark.svg';

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  active: {
    type: Boolean,
    default: false
  }
})

const days = ['Zo.', 'Ma.', 'Di.', 'Wo.', 'Do.', 'Vr.', 'Za.']
function dateToDay(date) {
  const fullDate = new Date(date)
  return days[fullDate.getDay()]
}

</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.time-block {
  display: flex;
  flex-direction: column;
  background: var(--green-dark_01);
  padding: rem(16) rem(24);
  border-radius: rem(16);
  justify-content: center;
  align-items: center;
  gap: rem(4);
  width: 100%;

  &.inactive {
    background: var(--green-dark_03);

    .date-wrapper {
      color: var(--green-dark_02);
    }

    .time-wrapper {
      color: var(--green-dark_02);
    }
  }

  .date-wrapper {
    color: white;
    font-weight: 500;
    color: #9AE1DC;
  }

  .time-wrapper {
    display: flex;
    gap: rem(8);
    align-items: center;
    color: white;
    font-weight: 600;
  }
}
</style>