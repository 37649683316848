<template>
  <div v-if="missionData" class="content-container">
    <PageTitle :title="classData.name || ' '" :sub-title="classData.schoolName || ' '" class="page-title">
    </PageTitle>

    <h1 class="page-section-title section-title">{{ $t('CLASSES_HELPDESK.INSIGHT') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')"
                :route-name="ROUTE_NAMES_HELPDESK.CLASS_DETAILS"
                :params="{ classId: classId }"/>

    <!-- Linked Teachers -->
    <p class="missions-count"><span style="font-weight: 500">{{ missionData.totalBitsEarned }}</span>{{ $t('CLASSES_HELPDESK.TOTAL_BITS') }}</p>
    <h1 class="page-section-title section-title">{{ $t('MISSIONS.TITLE') }}</h1>
    <CustomTable v-if="missionData.missions.length > 0"
                 :options="linkedMissionsOptions"
                 :table-data="missionData.missions"
                 :less-padding="true"
                 table-class="missions-table linked-classes"
                 class="classes-table">
      <template #order="props">
        <div>{{ props.rowData.dayOfMM }}</div>
      </template>
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #started="props">
        <div>{{ replaceEmptyWithDash(props.rowData.startedAt) }}</div>
      </template>
      <template #completed="props">
        <div>{{ replaceEmptyWithDash(props.rowData.finishedAt) }}</div>
      </template>
      <template #bits="props">
        <div>{{ replaceEmptyWithDash(props.rowData.bitsEarned) }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <!-- TODO change action buttons with correct images. -->
          <ActionButton
            v-if="lastPlayedMission && lastPlayedMission.id === props.rowData.id"
            :text="$t('CLASSES_HELPDESK.RESET')"
            :icon="require('../../../assets/icons/icn_refresh.svg')"
            custom-class="button-red"
            class="action"
            @click="openModalResetMission(props.rowData)"
          />
        </div>
      </template>
    </CustomTable>

    <div class="horizontal-layout">
      <MenuHorizontalItem text="Vragen" :active="(selectedItem === 0)" @click="setItem(0)"></MenuHorizontalItem>
      <MenuHorizontalItem text="Extra Games" :active="(selectedItem === 1)" @click="setItem(1)"></MenuHorizontalItem>
      <MenuHorizontalItem text="Media Quiz" :active="(selectedItem === 2)" @click="setItem(2)"></MenuHorizontalItem>
    </div>
    <InsightQuestions v-if="(selectedItem === 0)" ref="insightQuestionsEl" :on-edit-button-pressed="openModalEditBitsQuestion">Answers</InsightQuestions>
    <InsightExtraGames v-else-if="(selectedItem === 1)" ref="insightExtraGamesEl" :on-edit-button-pressed="openModalEditBitsExtraGame">ExtraGames</InsightExtraGames>
    <InsightMediaQuiz v-else-if="(selectedItem === 2)" ref="insightMediaQuizEl" :on-edit-button-pressed="openModalEditBitsExtraGame">MediaQuiz</InsightMediaQuiz>

    <BasicModal v-if="resetModalEnabled" />

    <BasicModal v-if="editBitsModalEnabled">
      <template #content>
        <BasicInputField
          field-title="Aantal bits"
          field-width="40%"
          field-name="bits-amount"
          :input-icon="require('@/assets/icons/icn_bits.svg')"
          :callback="onEditBitsInput"
          :value="editBitsInitial"
          class="bits-field"
        />
      </template>
    </BasicModal>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from '@vue/reactivity'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import BackButton from '@/components/elements/BackButton'
import MenuHorizontalItem from '@/components/elements/instructions/MenuHorizontalItem'
import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, LINKED_MISSIONS } from '@/utils/helpers/customTable/CustomTableHelper'
import ActionButton from '@/components/table/ActionButton'
import InsightQuestions from './InsightQuestions'
import InsightExtraGames from './InsightExtraGames'
import InsightMediaQuiz from './InsightMediaQuiz'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { SET_BASIC_MODAL_DATA, RESET_BASIC_MODAL_DATA } from '@/store/mutations'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicInputField from '@/components/elements/inputFields/BasicInputField'
import {
  EDIT_BITS_EXTRA_GAME,
  EDIT_BITS_MISSION_SLIDE,
  GET_MISSIONS_INSIGHT,
  RESET_MISSION
} from '@/store/modules/helpdesk/actions'

export default {
  name: 'ClassDetails',
  components: {
    BasicInputField,
    ActionButton,
    CustomTable,
    BackButton,
    PageTitle,
    InsightQuestions,
    InsightExtraGames,
    InsightMediaQuiz,
    MenuHorizontalItem,
    BasicModal
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const selectedItem = ref(0)
    const classId = route.params.classId
    const editBitsInput = ref(0)
    const editBitsInitial = ref(100)

    const insightQuestionsEl = ref(null)
    const insightExtraGamesEl = ref(null)

    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const resetModalEnabled = computed(() =>
      activeModal.value === MODAL_NAMES.RESET_MISSION
    )
    const editBitsModalEnabled = computed(() =>
      activeModal.value === MODAL_NAMES.EDIT_BITS
    )

    const classData = computed(() => store.getters.getClassDetails ?? {})

    const missionData = ref(null)

    getMissions()
    function getMissions() {
      store.dispatch(GET_MISSIONS_INSIGHT, classId).then(response => {
        missionData.value = response.data
        missionData.value.missions = missionData.value.missions.sort(compareMission)
      })
    }

    function compareMission(a, b) {
      if (a.dayOfMM > b.dayOfMM) {
        return 1
      } else if (a.dayOfMM === b.dayOfMM) {
        return 0
      } else {
        return -1
      }
    }

    const lastPlayedMission = computed(() => missionData.value ? missionData.value.missions.slice().reverse().find((mission) => mission.updatedAt !== null) : null)

    function viewDetails(clickedClass) {
      router.push({ name: ROUTE_NAMES_HELPDESK.CLASS_DETAILS, params: { classId: clickedClass.id } })
    }

    function openModalResetMission(rowData) {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.RESET_MISSION,
          cancelCallback: null,
          executeCallback: () => resetMission(rowData)
        },
        activeModal: MODAL_NAMES.RESET_MISSION
      }
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function openModalEditBits(rowData, callback, isQuestion) {
      const payload = {
        modalOptions: {
          modalContent: isQuestion ? BASIC_MODAL_CONTENT.EDIT_BITS_QUESTION : BASIC_MODAL_CONTENT.EDIT_BITS_EXTRA_GAME,
          cancelCallback: null,
          executeCallback: () => callback(rowData, editBitsInput.value)
        },
        activeModal: MODAL_NAMES.EDIT_BITS
      }
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function resetMission(rowData) {
      store.dispatch(RESET_MISSION, { classId, dayOfMM: rowData.dayOfMM }).then(() => {
        store.commit(RESET_BASIC_MODAL_DATA)
        getMissions()
      })
    }

    function setItem(item) {
      selectedItem.value = item
    }

    function editBitsExtraGame(extraGame, newAmountOfBits) {
      store.dispatch(EDIT_BITS_EXTRA_GAME, { extraGameId: extraGame.rowData.id, classId, bitsEarned: newAmountOfBits }).then(() => {
        insightExtraGamesEl.value.manageSearch({})
        store.commit(RESET_BASIC_MODAL_DATA)
      })
    }

    function editBitsQuestion(question, newAmountOfBits) {
      store.dispatch(EDIT_BITS_MISSION_SLIDE, { id: question.rowData.id, classId, bitsEarned: newAmountOfBits }).then(() => {
        insightQuestionsEl.value.manageSearch({})
        store.commit(RESET_BASIC_MODAL_DATA)
      })
    }

    function openModalEditBitsExtraGame(extraGame) {
      editBitsInitial.value = extraGame.rowData.bitsEarned
      openModalEditBits(extraGame, editBitsExtraGame, false)
    }

    function openModalEditBitsQuestion(question) {
      editBitsInitial.value = question.rowData.bitsEarned
      openModalEditBits(question, editBitsQuestion, true)
    }

    function onEditBitsInput(input) {
      editBitsInput.value = input.data
    }

    function replaceEmptyWithDash(value) {
      return value || value === 0 ? value : '-'
    }

    return {
      ROUTE_NAMES_HELPDESK,
      classId,
      classData,
      missionData,
      selectedItem,
      setItem,
      resetModalEnabled,
      editBitsModalEnabled,
      editBitsExtraGame,
      editBitsQuestion,
      openModalEditBitsExtraGame,
      openModalEditBitsQuestion,
      onEditBitsInput,
      replaceEmptyWithDash,
      editBitsInitial,
      insightQuestionsEl,
      insightExtraGamesEl,
      lastPlayedMission,
      openModalResetMission,
      openModalEditBits,

      /** Tables **/
      linkedMissionsOptions: getTableOptions(LINKED_MISSIONS),
      viewDetails,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.class-details-box {
  margin-top: rem(24);
  width: rem(746);

  .data-item {
    &:not(:last-of-type) {
      margin-bottom: rem(16);
    }
  }

  .edit-details {
    display: flex;
    margin-bottom: rem(56);
    margin-top: rem(40);
  }

  .half-width {
    width: 50%;
  }
}

.section-title {
  margin-top: rem(10);
  margin-bottom: rem(15);
}

/** Delete button **/
.delete-button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: rem(10) rem(21);

  .icon {
    margin-right: rem(15);
    width: rem(15);
  }
}

:deep .horizontal-layout {
  display: flex;
  flex-direction: row;
  margin-top: rem(30);
    .menu-item {
      .menu-item-label-text {
      font-size: rem(24);
      font-weight: bold;
    }
  }
  .active {
    .menu-item-label-text {
      color: var(--blue_dark);
      font-weight: bold;
    }
  }
}
.missions-count{
  margin: auto 0 auto auto;
  color: gray;
  text-align: right;
}

.bits-field {
  margin: 0 auto;
}
/** **/
</style>
