<template>
  <div>
    <div v-if="questionsData.items" class="header">
      <p class="questions-count"><span style="font-weight: 500">{{ questionsData.totalItems }}</span>{{ $t('CLASSES_HELPDESK.TOTAL_ANSWERS' ) }}</p>
    </div>
    <CustomTable :options="linkedQuestionsOptions"
                 :pagination-data="questionsData"
                 :table-data="questionsData.items"
                 :less-padding="true"
                 :search-function="manageSearch"
                 :hide-search="true"

                 table-class="schools-table linked-classes"
                 class="classes-table">
      <template #id="props">
        <div>{{ props.rowData.id }}</div>
      </template>
      <template #missionName="props">
        <div>{{ props.rowData.missionName }}</div>
      </template>
      <template #title="props">
        <div>{{ props.rowData.title }}</div>
      </template>
      <template #name="props">
        <div>{{ removeElements(replaceEmptyWithDash(props.rowData.question)) }}</div>
      </template>
      <template #time="props">
        <div>{{ parseMillisecondsIntoReadableTime(replaceEmptyWithDash(props.rowData.timeTaken)) }}</div>
      </template>
      <template #bits="props">
        <div>{{ replaceEmptyWithDash(props.rowData.bitsEarned) }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('CLASSES_HELPDESK.MODIFY')"
                        :icon="require('../../../assets/icons/icn_edit.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="() => onEditButtonPressed(props)"/>
        </div>
      </template>
    </CustomTable>

  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import ActionButton from '@/components/table/ActionButton'
import CustomTable from '@/components/table/CustomTable'
import { GET_QUESTIONS_INSIGHT } from '@/store/modules/helpdesk/actions'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { getTableOptions, LINKED_QUESTIONS } from '@/utils/helpers/customTable/CustomTableHelper'

export default {
  name: 'InsightQuestions',
  components: {
    CustomTable,
    ActionButton,
  },
  props: {
    onEditButtonPressed: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const classId = route.params.classId
    const questionsData = ref({})

    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      store.dispatch(GET_QUESTIONS_INSIGHT, { classId, payload }).then(response => {
        questionsData.value = response.data
      })
    }

    function modify(clickedClass) {
      console.log('modify request')
    }

    function replaceEmptyWithDash(value) {
      return value || value === 0 ? value : '-'
    }

    function removeElements(value) {
      return value.replaceAll(/<.+?>/g, '')
    }

    function parseMillisecondsIntoReadableTime(milliseconds) {
      //Get hours from milliseconds
      const hours = milliseconds / (1000 * 60 * 60)
      const absoluteHours = Math.floor(hours)
      const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours

      //Get remainder from hours and convert to minutes
      const minutes = (hours - absoluteHours) * 60
      const absoluteMinutes = Math.floor(minutes)
      const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes

      //Get remainder from minutes and convert to seconds
      const seconds = (minutes - absoluteMinutes) * 60
      const absoluteSeconds = Math.floor(seconds)
      const s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds


      return h + ':' + m + ':' + s
    }

    return {
      questionsData,
      modify,
      manageSearch,
      removeElements,
      replaceEmptyWithDash,
      parseMillisecondsIntoReadableTime,
      linkedQuestionsOptions: getTableOptions(LINKED_QUESTIONS),
    }
  },
  template: 'InsightQuestions'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

h1 {
  margin: rem(30) 0 rem(20) 0;
}

.cell-custom-table {
  padding: 0 0 0 0;
}

.questions-count{
  margin: auto 0 auto auto;
  color: gray;
  text-align: right;
}

.header{
  display: flex;
}

.status{
  font-weight: 800;
}

.published{
  color: rgb(81,84,104)
}

.concept{
 color: rgb(123,150,179)
}
</style>
