<template>
  <div>
    <div v-if="mediaQuizData?.questionsInsights?.items?.length" class="header">
      <div class="header-item-wrapper">
        <p class="header-item-slim">{{ $t('TIME_NEEDED') }}</p>
      </div>
      <div class="header-item-wrapper">
        <p class="header-item">{{ $t('TOTAL_TIME') }}: {{ formatMilliseconds(mediaQuizData?.totalTimeTakenInMilliseconds) }}</p>
        <p class="header-item">{{ $t('TOTAL_BITS') }}: {{ mediaQuizData?.totalBits ?? 0 }}</p>
      </div>
    </div>

    <div v-if="mediaQuizData?.questionsInsights?.items?.length" class="date-time-wrapper">
      <DateTimeBlock
        v-for="(item, index) in mediaQuizData.daysInsights"
        :key="index"
        :item="{date: item.date, time: item.timeTakenInMilliseconds}"
        :active="new Date(item.date) < new Date()"
      />
    </div>

    <CustomTable v-if="mediaQuizData"
                 :options="linkedMediaQuizzes"
                 :pagination-data="mediaQuizData?.questionsInsights"
                 :table-data="mediaQuizData?.questionsInsights?.items"
                 :less-padding="true"
                 :hide-search="true"
                 :search-function="manageSearch"
                 table-class="schools-table linked-classes"
                 class="classes-table">
      <template #repliedAt="props">
        <div class="date-column">{{ props.rowData.repliedAt }}</div>
      </template>
      <template #mediaquizNumber="props">
        <div>{{ props.rowData.mediaquizNumber }}</div>
      </template>
      <template #question="props">
        <div>{{ formatQuestion(props.rowData.question) }}</div>
      </template>
      <template #bitsAmount="props">
        <div>{{ props.rowData.bitsAmount }}</div>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import {  ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, LINKED_MEDIAQUIZZES } from '@/utils/helpers/customTable/CustomTableHelper'
import { GET_MEDIA_QUIZ_INSIGHT } from '@/store/modules/helpdesk/actions'
import DateTimeBlock from '@/views/helpdesk/classes/DateTimeBlock.vue'
import { formatMilliseconds } from '@/utils/helpers/FormatTime.js';


export default {
  name: 'InsightMediaQuiz',
  components: {
    CustomTable,
    DateTimeBlock
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const classId = route.params.classId
    const mediaQuizData = ref({})

    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      store.dispatch(GET_MEDIA_QUIZ_INSIGHT, { classId, payload }).then(response => {
        mediaQuizData.value = response.data
      })
    }

    function formatQuestion(question) {
      return question.length > 125 ? question.substring(0, 125) + '...' : question
    }

    return {
      manageSearch,
      formatQuestion,
      mediaQuizData,
      linkedMediaQuizzes: getTableOptions(LINKED_MEDIAQUIZZES),
      formatMilliseconds
    }
  },
  template: 'InsightMediaQuiz',
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

h1 {
  margin: rem(30) 0 rem(20) 0;
}

.cell-custom-table {
  padding: 0 0 0 0;
}

.questions-count{
  margin: auto 0 auto auto;
  color: gray;
  text-align: right;
}

.header{
  display: flex;
  justify-content: space-between;
  margin-top: rem(24);
}

.header-item-wrapper {
  display: flex;
  gap: rem(24);
}

.header-item {
  color: var(--blue_light_04);
  font-weight: 600;
}

.header-item-slim {
  color: var(--blue_light_04);
  font-weight: 400;
}

.date-time-wrapper {
  display: flex;
  gap: rem(16);
  margin: rem(12) 0 rem(16);
  overflow-x: auto;
  max-width: calc(100vw - rem(355));
  scrollbar-color: var(--blue_light) var(--gray_light);
}

.status{
  font-weight: 800;
}

.published{
  color: rgb(81,84,104)
}

.concept{
 color: rgb(123,150,179)
}

.date-column {
  white-space: nowrap;
}
</style>
